import { ReactNode } from 'react'

/**
  Use these layout components like so:
  return (
    <StepWrapper>
      <ScrollableStepContent>
        { Your step content here }
      </ScrollableStepContent>
      <StepButtonsWrapper>
        {/* Example buttons  *\/}
        <Button kind='tertiary' onClick={() => navigate('/')}>
          Cancel
        </Button>
        <Button kind='primary' onClick={() => submitStep({ ... })}>
          Next
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
*/
export const StepWrapper = ({ children }: { children: ReactNode }) => {
  return <div className={`flex w-full flex-1 flex-col items-center justify-center`}>{children}</div>
}
