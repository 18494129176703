// Copied from:
// https://github.com/alexreardon/tiny-assert/blob/main/src/assert.ts
// This is the guy who created the tiny-invariant package, but assert is a much clearer name (why he created tiny-assert)
export function assert(
  condition: any,
  // Can provide a string, or a function that returns a string for cases where
  // the message takes a fair amount of effort to compute
  message?: string | (() => string),
): asserts condition {
  if (condition) {
    return
  }

  // Condition not passed
  throw new Error(typeof message === 'function' ? message() : message)
}
