import clsx from 'clsx'
import { ReactNode } from 'react'

/*
The scrollable, fixed-height "portal" thing is a little tricky, but this thing works pretty well.
Keeps the header/footer in place, and the content scrollable and hides the corners of the scrollbar.

You'll have to add 'w-full' and 'p-6' to the parent div placed within this component - didn't
add that here to make it possible to add full width/height dividers if needed, etc.

Single column layout, just use the ScrollableStepContent component:
  <ScrollableStepContent>{entityDetailsColumn}</ScrollableStepContent>

Two column layout, use this pattern and tweak column flex/width as needed
  <div className='flex flex-1 gap-6 w-full'>
    <div className='w-1/4 flex flex-1'>
      <ScrollableStepContent>{entityNavColumn}</ScrollableStepContent>
    </div>
    <div className='w-3/4 flex'>
      <ScrollableStepContent>{entityDetailsColumn}</ScrollableStepContent>
    </div>
  </div>
*/
export const ScrollableStepContent = ({
  children,
  className,
  inset = false,
  verticallyCentered = false,
}: {
  children: ReactNode
  className?: string
  inset?: boolean
  verticallyCentered?: boolean
}) => (
  <div className={clsx('relative flex w-full flex-1 flex-col', className)}>
    {/* This absolute positioning trick extends div to parent borders, and allows to hide content regardless
        of parent height (otherwise you generally have to set a fixed height to get overflow-scroll/auto to work correctly)*/}
    <div
      className={clsx(
        'absolute bottom-0 left-0 right-0 top-0 flex w-full flex-1 overflow-hidden',
        inset ? 'bg-uibg-1' : 'bg-white',
      )}
    >
      <div
        className={clsx(
          'flex w-full justify-center overflow-y-auto py-8',
          inset ? 'bg-uibg-1 shadow-uibg-7 shadow-inner-lg border-uibg-7 border-y' : 'bg-white',
        )}
      >
        {verticallyCentered ? <div className='grid w-full place-items-center px-4 pb-16'>{children}</div> : children}
      </div>
    </div>
  </div>
)
